import axios from 'axios'

import { getFullURL, isRefreshRequest, isGuestRequest } from '@/utils/http'
import { useAuthStore } from '@/store'

const instance = axios.create({
  // Web 侧可以通过 vite.config.js 中的 proxy 配置，指定代理
  // 小程序APP里需写完整路径，如 https://service-rbji0bev-1256505457.cd.apigw.tencentcs.com/release
  // 可使用条件编译,详见 https://uniapp.dcloud.io/tutorial/platform.html#preprocessor
  // #ifdef H5
  baseURL: import.meta.env.VITE_APP_AXIOS_BASE_URL,
  // #endif
  // #ifndef H5
  // @ts-ignore
  // baseURL: 'easygo.test/api',
  // #endif
  adapter(config) {
    const { url, method, data, params, headers, baseURL, paramsSerializer } = config
    return new Promise((resolve, reject) => {
      console.log('request', data, params)
      if (data instanceof FormData) {
        const files: Array<{ name: string; file: Blob }> = []
        const uploadParams = new URLSearchParams()
        for (const [key, value] of data.entries()) {
          if (value instanceof Blob) {
            files.push({
              name: key,
              file: value
            })
          }
        }

        uni.uploadFile({
          url: getFullURL(baseURL || '', url!, params, paramsSerializer),
          header: headers,
          files: files as UniNamespace.UploadFileOptionFiles[],
          // formData: data,
          success: (res: any) => {
            res.config = config
            resolve(res)
          },
          fail: (err: any) => {
            reject(err)
          }
        })
      } else {
        uni.request({
          method: method!.toUpperCase() as any,
          url: getFullURL(baseURL || '', url!, params, paramsSerializer),
          header: headers,
          data,
          dataType: 'json',
          responseType: config.responseType,
          success: (res: any) => {
            if (res.statusCode === 400) {
              uni.showToast({
                title: res.data.message,
                icon: 'error'
              })
            }
            if (res.statusCode === 422) {
              uni.showToast({
                title: res.data.message,
                icon: 'error'
              })
            }
            // if (res.statusCode === 200) {
            //   // uni.showToast({
            //   //   title: res.data.message,
            //   //   icon: 'success'
            //   // })
            // }
            res.config = config
            resolve(res)
          },
          fail: (err: any) => {
            uni.showToast({
              title: '请求失败',
              icon: 'error'
            })

            reject(err)
          }
        })
      }
    })
  }
})

/**
 * 请求拦截
 */
instance.interceptors.request.use((config) => {
  const { method, params, data } = config
  const authStore = useAuthStore()
  // 附带鉴权的token
  const headers: any = {
    authorization: `${authStore.tokenType} ${authStore.token}`
  }
  // 不缓存get请求
  if (method === 'get') {
    headers['Cache-Control'] = 'no-cache'
  }
  // delete请求参数放入body中
  if (method === 'delete') {
    headers['Content-type'] = 'application/json;'
    Object.assign(config, {
      data: params,
      params: {}
    })
  }

  return {
    ...config,
    headers
  }
})

/**
 * 响应拦截
 */
instance.interceptors.response.use(async (res: any) => {
  // @ts-ignore
  if (res.statusCode === 401 && !isRefreshRequest(res.config) && !isGuestRequest(res.config)) {
    const authStore = useAuthStore()
    const refreshSuccess = await authStore.refreshToken()

    if (refreshSuccess) {
      if (res.config.headers) {
        res.config.headers.Authorization = `${authStore.tokenType} ${authStore.token}`
      }

      const resp = await instance.request(res.config)
      return resp.data
    }

    await authStore.resetData()

    uni.navigateTo({
      url: '/pages/auth/login'
    })
    return res.data
  }

  // @ts-ignore
  if (res.statusCode >= 200 && res.statusCode <= 300) {
    return res.data
  }
  // alert(v.statusText, '网络错误')
  return Promise.reject(res)
})

export default instance
