import http from '../http'

/**
 * 获取验证码
 * @param phone 手机号
 */
function getCurrentUser() {
  return http.get('user')
}

function idCardUpdate(idcard: string, data: any) {
  return http.post(`users/${idcard}/idcard`, data)
}

export default {
  getCurrentUser,
  idCardUpdate
}
